import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import YAMap from "./YAMap"


const DeliveryTemplate = () => (
  <Layout>
    <SEO title="Доставка кабеля авто, жд по России и СНГ" />
    <main>
      <h1>Доставка</h1>
      <h2>Выделенный транспорт</h2>
      <p>Наш транспорт доставит продукцию на любой ваш объект в кратчайшие сроки. Уралпромкабель имеет большой опыт по доставке кабеля выделенным автотранспортом, спецтранспортом, авиа и ж/д даже в самые отдаленные районы,
        в труднодоступные места, где отсутствует автомобильное сообщение.</p>
      <h2>Транспортные компании</h2>
      <p>Работаем со всеми крупными транспортными компаниями по доставке сборных грузов:</p>
        <ul>
          <li><a href="https://www.dellin.ru/">Деловые линии</a></li>
          <li><a href="https://gtdel.com">GTD</a></li>
          <li><a href="https://pecom.ru/">ПЭК</a></li>
          <li><a href="https://cdek.ru">СДЕК</a></li>
          <li><a href="https://www.baikalsr.ru/">Байкал Сервис</a></li>
          <li><a href="https://nrg-tk.ru/">ТК Энергия</a></li>
        </ul>
      <h2>Самовывоз</h2>
      <p>Самостоятельный забор продукции возможен из Перми и Екатеринбурга. Для получения необходима доверенность от покупателя.<br/>
      Внимание! Погрузка кабеля на барабанах возможна только в машины с деревяным полом.</p>
      <p>Адреса и часы работы:</p>
      <div>
        <dl><dt><b>Пермь</b></dt>ул. Васнецова, 12а<br/>c 8:00 до 16:00 пн. - пт. Обед с 12:00 до 13:00</dl>
        <dl><dt><b>Екатеринбург</b></dt>ул. Полевая, 76<br/>c 9:00 до 16:00 пн. - пт. Без обеда</dl>
      </div>
      <h2>Карта поставок</h2>
      <YAMap/>
    </main>
  </Layout>
)

export default DeliveryTemplate
